export default class NoticeModel {
  constructor(){
    this.objRaw = {};
    this.id = '';
    this.type = '';
    this.content = '';
    this.deploy_at = '';
    this.deploior = '';
    this.status = '';
    this.isEssential = true;
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, content, deploy_at, deploior, status, isEssential } = obj;
    this.id = id;
    this.content = content;
    this.deploy_at = deploy_at;
    this.deploior = deploior;
    this.status = status;
    this.isEssential = isEssential;
  }
  getData(){
    let obj = {
      id : this.id,
      content : this.content,
      deploy_at : this.deploy_at,
      isEssential : this.isEssential,
    }
    return obj;
  }
}